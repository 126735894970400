import * as React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '~/utils/misc.tsx'
import { StatusEnum } from '../../../types/enums.ts'
import { type UserStatusType } from '~/models/user.ts'

export const getBadgeVariantByStatus = (status: UserStatusType) => {
  switch (status) {
    case StatusEnum.ACTIVE:
      return { statusText: 'Activo', badgeVariant: 'green' }
    case StatusEnum.PENDING:
      return { statusText: 'Pendiente', badgeVariant: 'orange' }
    case StatusEnum.SUSPENDED:
      return { statusText: 'Suspendido', badgeVariant: 'red' }
    default:
      return { statusText: '', badgeVariant: 'default' }
  }
}

export const getBooleanBadge = (boolean: boolean | undefined) => {
  if (boolean) {
    return { statusText: 'Si', badgeVariant: 'green' }
  }

  return { statusText: 'NO', badgeVariant: 'red' }
}

const badgeVariants = cva(
  'inline-flex items-center text-xs font-bold rounded-md h-[24px] min-w-[24px] border px-1.5   transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
  {
    variants: {
      variant: {
        default: 'border-transparent bg-primary text-white hover:bg-primary/80',
        red: 'border-badge-red-border bg-badge-red text-badge-red-foreground border',
        orange: 'bg-badge-orange text-badge-orange-foreground border',
        green:
          'border-badge-green-border bg-badge-green text-badge-green-foreground border',
        gray: ' bg-gray-200 text-foreground ',
        secondary: 'border-secondary text-secondary',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  )
}

export { Badge, badgeVariants }
